export const rangeToday = [getZeroTime(), getZeroTime() + 3600 * 1000 * 24 - 1]
export const rangeYesterday = [getZeroTime() - 3600 * 1000 * 24 * 1, getZeroTime() - 1]
export const rangeWeek = [getZeroTime() - 3600 * 1000 * 24 * 7, getZeroTime() + 3600 * 1000 * 24 - 1]

export const rangeMonth = [getZeroTime() - 3600 * 1000 * 24 * 30, getZeroTime() + 3600 * 1000 * 24 - 1]

// 获取日期区间的时间戳
export function getTime(start, end) {
  return [getZeroTime(start), getZeroTime(end) + 3600 * 1000 * 24 - 1]
}

function getZeroTime(time) {
  let date = ''
  if (time) {
    date = new Date(time)
  } else {
    date = new Date()
  }

  return new Date(date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()).getTime()
}

export function formatOnlyDate(date) {
  try {
    if (typeof date === 'string' || typeof date === 'number') {
      date = new Date(date)
    }
    let month = date.getMonth() + 1
    month = month + '月'
    let day = date.getDate()
    day = day + '日'
    return [month, day].map(formatNumber).join('-')
  } catch (err) {
    return '--'
  }
}

export function formatTime(date) {
  try {
    if (typeof date === 'string' || typeof date === 'number') {
      date = new Date(date)
    }
    let year = date.getFullYear()
    year = year
    let month = date.getMonth() + 1
    month = month
    let day = date.getDate()
    day = day
    return [year, month, day].map(formatNumber).join('-')
  } catch (err) {
    return '--'
  }
}

function formatNumber(n) {
  n = n.toString()
  return n[1] ? n : '0' + n
}
