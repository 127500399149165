<template lang="pug">
.page
  .title 审核列表
  .recordList
    .recordItem(v-for="i in recordList" :key="i.id" @click="handleItem(i)")
      .status {{statusMap[i.verifyStatus]}}
      .name 修改人:{{i.updateUserName}}
      .date {{formatTime(i.createTime)}}
      el-icon
        ArrowRight
  //- .pagination
  //-   el-pagination(layout="total, prev, pager, next" background v-model:page-size="pagination.size" :pager-count="isMobile?3:5" :page-sizes="[10, 20, 50, 100]" :total="pagination.total" v-model:current-page="pagination.page" @current-change="getList")

</template>

<script setup>
import { inject, onMounted, computed, ref } from 'vue'
import { ArrowRight } from '@element-plus/icons-vue'
import { currentMarketId } from '@/provider/account'
import { isMobile } from '@/provider/sys'
import { formatTime } from '@/provider/time'
import { updateCache } from '@/provider/cache'

const { fetch, router, message, ElMessageBox } = inject('global')
const pagination = ref({
  page: 1,
  size: 10,
  total: 10
})

const statusMap = ['审核中', '已通过', '未通过', '已取消']

const recordList = ref([])

// 获取修改记录列表
function getList() {
  let params = {
    module: 'basic_information'
    // ...pagination.value
  }
  fetch
    .get(`/boom-center-product-service/businessAdmin/commercialPlazaAssistant/updateRecord/${currentMarketId.value}`, {
      params
    })
    .then(res => {
      recordList.value = res
      // pagination.value.total = Number(res.total)
    })
    .catch(err => {
      message.err(err.msg || err)
    })
}

// 点击了审核元素
function handleItem(item) {
  updateCache('recordInfo', item.updateData, { expireTime: 0 })
  router.push('/record/info')
}

async function initData() {
  pagination.value.page = 1
  getList()
}

onMounted(() => {
  initData()
})
</script>

<style lang="less" scoped>
.title {
  background-color: #fff;
  font-size: 18px;
  padding: 10px;
  text-align: center;
  font-weight: 600;
}
.recordList {
  --gutter: 10px;
  --logoSize: 80px;
  --checkboxSize: 50px;
  --size-text-title: 18px;
  --size-text-desc: 14px;
  display: flex;
  flex-wrap: wrap;
  .recordItem {
    .trans();
    margin: var(--gutter);
    padding: var(--gutter);
    background: white;
    border-radius: var(--gutter);
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-basis: 300px;
    border: 1px solid;
    border-color: var(--color-border);
    &:hover {
      box-shadow: 0 0 12px var(--color-border);
    }
    .name {
      flex: 1;
      padding: 0 15px;
    }
    .date {
      padding-right: 5px;
    }
  }
}
.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
}

.isMobile {
  .recordList {
    --gutter: 10px;
    --logoSize: 40px;
    --checkboxSize: 30px;
    --size-text-title: 14px;
    --size-text-desc: 12px;
  }
}
</style>
